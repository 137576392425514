/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf("android") != -1;

                function fadeInImages() {

                    $(".fadeout img").each(function() {
                        var $thisImg = $(this);
                        var $parents = $thisImg.parents(".fadeout").first().hide();

                        if (!isAndroid) {
                            $parents.show();
                        }

                        if ($thisImg[0].complete) {
                            $parents.removeClass("fadeout");
                            $parents.fadeIn(300, function() {
                                $parents.addClass("fadein");
                            });
                        }

                        $thisImg.on("load", function() {
                            $parents.removeClass("fadeout");
                            $parents.fadeIn(300, function() {
                                $parents.addClass("fadein");
                            });
                        });

                        console.log("fadein triggers");
                    });
                }

                fadeInImages();

                var deferBGImages = function() {

                    var $bgFills = jQuery(".fullpage-wrapper .vc_row-has-fill");
                    $bgFills.each(function() {

                        var customStyle = {
                            "background-color": jQuery(this).css("background-color"),
                            "background-image": jQuery(this).css("background-image"),
                            "background-size": jQuery(this).css("background-size"),
                            "background-repeat": jQuery(this).css("background-repeat"),
                            "background-position": jQuery(this).css("background-position"),
                        }

                        if (customStyle["background-image"].indexOf("url") == -1) return true;

                        var bgImageUrl = customStyle["background-image"].replace(/(url\(|\)|'|")/gi, '');
                        var $img = jQuery("<img />");
                        if ($img.attr("src", bgImageUrl)[0].complete) {
                            $img.remove();
                            customStyle = null;
                            return true;
                        }

                        jQuery(this).data("customStyle", customStyle);
                        jQuery(this)[0].style.setProperty('background', 'none', 'important');
                        jQuery(this)[0].style.setProperty('background-color', customStyle["background-color"], 'important');
                    });
                }

                $(".section-work").eq(1).removeClass("dark-theme");

                function initFullpage() {

                    if ($('#work-single').length)
                        $('#work-single').fullpage({
                            slideSelector: ".vc_row:not(.vc_inner)",
                            sectionSelector: ".vc_row:not(.vc_inner)",
                            autoScrolling: true,
                            css3: true,
                            bigSectionsDestination: "top",
                            scrollingSpeed: 300,
                            continuousVertical: true,
                            afterRender: function() {
                                preloadPages();
                                deferBGImages();

                                if (window.fullpageTimeout) clearTimeout(window.fullpageTimeout);
                                window.fullpageTimeout = setTimeout(function() {
                                    console.log("work-single fullpage ready...");

                                    if ($('.fp-section:first').attr("class").indexOf('dark-theme') != -1) {
                                        $('body').addClass('dark-theme');
                                    } else {
                                        $('body').removeClass('dark-theme');
                                    }

                                    if ($(".fp-section:first").is(".active")) {
                                        $('body').addClass('page-first-section');
                                    } else {
                                        $('body').removeClass('page-first-section');
                                    }

                                    window.fullpageReady = true;

                                    fadeInBgImage();
                                    fadeInImages();

                                    var numSection = $(".fp-section").length;
                                    // for one-section pages
                                    if (numSection <= 2) {
                                        window.oneSection = true;
                                        var $clone = $(".fp-section").clone(true, true);

                                        for (var i = 0; i <= numSection; i++) {
                                            $clone.insertAfter(".fp-section");
                                        }

                                        destroyFullpage();
                                        initFullpage();

                                        $(".current").removeClass("opaqueHidden");
                                        window.fromPrev = false;

                                    } else if (!window.oneSection) {
                                        // redirect to last section if from prev
                                        // if (window.fromPrevTimeout) clearTimeout(window.fromPrevTimeout);
                                        // window.fromPrevTimeout = setTimeout(function () {
                                        //   if (window.fromPrev) {
                                        //     console.log("triggered previous");

                                        //     $.fn.fullpage.setScrollingSpeed(0);
                                        //     // loop scrolling, since direct moveTo doesn't work
                                        //     for (var i = 1; i <= $(".fp-section").length; i++) {
                                        //       $.fn.fullpage.moveTo(i);
                                        //     }

                                        //     $(".current").removeClass("opaqueHidden");

                                        //     $.fn.fullpage.setScrollingSpeed(500);
                                        //     // set fromPrev to false
                                        //     window.fromPrev = false;
                                        //   }
                                        // }, 700);
                                    }
                                }, 700);
                            },
                            onLeave: function(index, nextIndex, direction) {
                                $('body').removeClass('page-last-section page-first-section work-footer-active footer-last-section');

                                // only remove dark theme if next section doesn't have dark-theme class
                                if (!$(".fp-section").eq(nextIndex - 1).hasClass("dark-theme")) {
                                    $('body').removeClass("dark-theme");
                                }

                                var numSections = $(".fp-section").length;

                                if (window.reset || !window.fullpageReady) {
                                    // check if fullpage is ready for viewing
                                    return false;
                                } else if (window.oneSection) {
                                    window.oneSection = false;
                                    if (index == 1 && nextIndex == 2) {
                                        if ($(".work-next-project").length) {

                                            // trigger an ajax call to next link
                                            $(".work-next-project").trigger("click");

                                            // set fullpage not ready
                                            window.fullpageReady = false;
                                        }
                                        return false;

                                        console.log("next");
                                    } else if (index == 1 && nextIndex == numSections) {
                                        if ($(".work-prev-project").length) {
                                            // trigger an ajax call to previous link
                                            $(".work-prev-project").trigger("click");

                                            // set fullpage not ready
                                            window.fullpageReady = false;
                                            window.fromPrev = true;
                                        }
                                        return false;

                                        console.log("prev");
                                    }

                                } else if (index == 1 && nextIndex == numSections) {

                                    if ($(".work-prev-project").length) {

                                        // trigger an ajax call to previous link
                                        $(".work-prev-project").trigger("click");

                                        // set fullpage not ready
                                        window.fullpageReady = false;
                                        window.fromPrev = true;

                                    }
                                    return false;
                                } else if (index == numSections && nextIndex == 1) {
                                    if ($(".work-next-project").length) {

                                        // trigger an ajax call to next link
                                        $(".work-next-project").trigger("click");

                                        // set fullpage not ready
                                        window.fullpageReady = false;
                                    }
                                    return false;
                                }

                                $.fn.fullpage.setAllowScrolling(false);

                            },
                            afterLoad: function(anchorLink, index) {

                                preloadPages();

                                if ($('.fp-section.active').hasClass('dark-theme')) {
                                    $('body').addClass('dark-theme');
                                } else {
                                    $('body').removeClass('dark-theme');
                                }

                                if ($('.fp-section-work-footer.active').length) {
                                    $('body').addClass('work-footer-active');
                                }

                                // last section loaded
                                if (index == 1) {
                                    $('body').addClass('page-first-section');
                                }

                                // allow scrolling after 700ms to avoid skipping due to inertia
                                if (window.allow) clearTimeout(window.allow);
                                window.allow = setTimeout(function() {
                                    $.fn.fullpage.setAllowScrolling(true);
                                }, 700);

                                // last section loaded

                                //alert(index );
                                if (index == $('.vc_row:not(.vc_inner)').length) {
                                    $('body').addClass('page-last-section');
                                    if ($(".section-footer").length) {
                                        $('body').addClass('footer-last-section');
                                    }
                                }

                                $(document).on("click", ".modal-close-video", function() {
                                    $(".modal-player").fadeOut(500, function() {
                                        var $iframe = $(".modal-player iframe");
                                        $iframe.attr("src", $iframe.attr("src").replace(/&autoplay=1|\?autoplay=1/, ""));
                                    });
                                });


                                $(document).on("click", '.play-button button', function() {
                                    $(".modal-player").fadeIn(500, function() {
                                        var $iframe = $(".modal-player iframe");
                                        if ($iframe[0].src.indexOf("?") != -1) {
                                            $iframe[0].src += "&autoplay=1";
                                        } else {
                                            $iframe[0].src += "?autoplay=1";
                                        }
                                    });
                                });
                            }
                        });

                    if ($('#fullpageVC').length)
                        $("#fullpageVC").fullpage({
                            slideSelector: ".vc_row:not(.vc_inner)",
                            css3: true,
                            bigSectionsDestination: "top",
                            sectionSelector: ".vc_row:not(.vc_inner)",
                            scrollingSpeed: 300,
                            afterRender: function() {
                                preloadPages();
                                deferBGImages();
                                fadeInBgImage();

                            },
                            onLeave: function(index, nextIndex, direction) {
                                if (window.reset) {
                                    return false;
                                }

                                $('body').removeClass('page-first-section work-footer-active');

                                $.fn.fullpage.setAllowScrolling(false);
                            },

                            afterLoad: function(anchorLink, index) {

                                if (window.allow) clearTimeout(window.allow);
                                window.allow = setTimeout(function() {
                                    $.fn.fullpage.setAllowScrolling(true);
                                }, 700);

                                if ($('.fp-section-work-footer.active').length) {
                                    $('body').addClass('work-footer-active');
                                }

                                if ($('.fp-section.active').hasClass('dark-theme')) {
                                    $('body').addClass('dark-theme');
                                } else {
                                    $('body').removeClass('dark-theme');
                                }

                                // last section loaded
                                if (index == 1) {
                                    $('body').addClass('page-first-section');
                                }
                            }
                        });

                    if ($('#fullpageWork').length)
                        $('#fullpageWork').fullpage({
                            menu: '#navWork',
                            css3: true,
                            animateAnchor: false,
                            bigSectionsDestination: "top",
                            scrollingSpeed: 300,
                            afterRender: function() {
                                preloadPages();

                                deferBGImages();
                                fadeInBgImage();

                                // check if mobile dark theme is enabled
                                if ($(".section-work").eq(0).find(".mobile-dark-theme").length) {
                                    $("body").addClass("mobile-dark-theme");
                                } else {
                                    $("body").removeClass("mobile-dark-theme");
                                }

                                var $menuAnchor = $(".active[data-menuanchor='brand']");
                                $menuAnchor.addClass("isolated");

                                // redirect to hash
                                if (window.hash) {
                                    if (window.redirectTimeout) clearTimeout(window.redirectTimeout);
                                    window.redirectTimeout = setTimeout(function() {
                                        $.fn.fullpage.silentMoveTo(window.hash, 1);
                                        window.hash = "";
                                        $("html").removeClass("opaqueHidden");
                                    }, 1550);
                                }

                            },
                            afterLoad: function(anchorLink, index) {

                                if (window.allow) clearTimeout(window.allow);
                                window.allow = setTimeout(function() {
                                    $.fn.fullpage.setAllowScrolling(true);
                                }, 750);

                                // check if mobile dark theme is enabled
                                var $currentSection = $(".fp-section").eq(index - 1);
                                if ($currentSection.find(".mobile-dark-theme").length || $currentSection.is(".section-footer")) {
                                    $("body").addClass("mobile-dark-theme");
                                } else {
                                    $("body").removeClass("mobile-dark-theme");
                                }

                                if (index == 1) {
                                    $('body').addClass('page-first-section');
                                } else {
                                    $('body').removeClass('page-first-section');
                                }
                                if (anchorLink) {
                                    anchorLink = anchorLink.replace(/\d/g, "");
                                    var $menuAnchor = $("[data-menuanchor='" + anchorLink + "']");

                                    $menuAnchor.siblings().removeClass("isolated");
                                    $menuAnchor.addClass("isolated");

                                    if (!$menuAnchor.length) {
                                        $(".isolated").removeClass("isolated");
                                    }
                                }

                            },
                            onLeave: function(index, nextIndex, direction) {
                                if (window.reset) {
                                    return false;
                                }

                                $.fn.fullpage.setAllowScrolling(false);
                            }
                        });

                    if ($('#fullpageHome').length)
                        $('#fullpageHome').fullpage({
                            anchors: [
                                'home',
                                'work',
                                'work_2',
                                'clients',
                                'footer',
                            ],
                            menu: '.link-trigger',
                            css3: true,
                            fitToSection: true,
                            bigSectionsDestination: "top",
                            scrollingSpeed: 300,
                            afterRender: function() {
                                // Owl carousel
                                $(".slick").slick();

                                deferBGImages();
                                fadeInBgImage();
                                preloadPages();

                            },
                            onLeave: function(index, nextIndex, direction) {
                                if (window.reset) {
                                    return false;
                                }

                                if (index == 1 && nextIndex == $('.section').length) {
                                    return false;
                                }

                                $('body').removeClass('dark-theme');

                                $.fn.fullpage.setAllowScrolling(false);
                            },
                            afterLoad: function(anchorLink, index) {

                                if ($('.section.active').hasClass('dark-theme')) {
                                    $('body').addClass('dark-theme');
                                }

                                if (window.allow) clearTimeout(window.allow);
                                window.allow = setTimeout(function() {
                                    $.fn.fullpage.setAllowScrolling(true);
                                }, 700);
                            }

                        });

                    window.rebuildFullpage(1000);

                }

                window.rebuildFullpage = function(_ms) {
                    $(window).scrollTop(-1);
                    if (window.rebuildTimeout) clearTimeout(window.rebuildTimeout);
                    window.rebuildTimeout = setTimeout(function() {
                        if (document.documentElement.clientHeight < 1400) {

                            var screenHeight = document.documentElement.clientHeight;
                            if (navigator.userAgent.match(/iPad/i) != null) {
                                screenHeight += 17;
                            }
                            
                            $(".fp-section").css({
                                "height": screenHeight + "px",
                                "min-height": screenHeight + "px",
                            });

                            $.fn.fullpage.reBuild();
                            console.log("rebuild");
                        }
                    }, _ms);
                }

                $(document).on("ready", function() {
                    window.rebuildFullpage(500);
                });



                function destroyFullpage() {
                    $("*").fullpage.destroy('all');
                }

                function changeUrl(page, url) {
                    if (typeof(history.pushState) != "undefined") {
                        var obj = { Page: page, Url: url };
                        history.pushState(obj, obj.Page, obj.Url);
                    } else {
                        alert("Browser does not support HTML5.");
                    }

                }

                initFullpage();


                function preloadPages($linkArray) {
                    if ($linkArray == undefined) {
                        $linkArray = $(".fp-section a:not([href*='#']), a.sidebar-back-link, a.work-prev-project, a.work-next-project");
                    }

                    $linkArray.each(function(item) {
                        var $thisLink = $(this);
                        var href = $thisLink.attr("href");

                        if (href.indexOf(window.location.hostname) !== -1 && !$thisLink.data("loaded")) {
                            $.get(href, function(response) {
                                var parser = new DOMParser();
                                var newHTML = parser.parseFromString(response, "text/html");

                                $thisLink.data("newHTML", newHTML);
                                $thisLink.data("loaded", true);

                            });
                        }
                    });
                }

                preloadPages($("a[href*='/about'], a[href='/'], a.sidebar-back-link"));

                function initWantedScript() {
                    var scriptSrc = [
                        "https://www.tenfoldcreative.co.nz/wp-content/plugins/js_composer/assets/js/dist/js_composer_front.min.js?ver=5.4.5"
                    ];

                    var scriptString = "";
                    scriptSrc.forEach(function(src) {
                        var $script = $("script[src='" + src + "']");
                        if (!$script.length) {
                            scriptString += "<script src='" + src + "' async></script>";
                        }
                    });

                    $("body").append(scriptString);

                }

                function swipeBodyEvent() {
                    $(document).on("swiperight", function() {
                        if ($(".sidebar-back-link").length) {
                            $(".sidebar-back-link")[0].click();
                        }
                    });
                }

                swipeBodyEvent();

                function initVideoControls() {
                    if ($(".play-button").length && $(".pause-button".length)) {
                        $(".play-button").show();
                        $(".pause-button").hide();
                    }
                }

                initVideoControls();

                function replaceCurrent(newHTML, href) {

                    var $styles = $(newHTML).find("style");
                    var js_composer_css = $(newHTML.getElementById("js_composer_front-css"));
                    var scripts = "";

                    var count = 1;
                    var $prevStyles = $("[data-uid^='style']");
                    $styles.each(function() {
                        if ($(this).is("#dynamicStyling")) return true;

                        $(this).attr("data-uid", "style" + count++);
                        $(this).insertBefore($("#dynamicStyling"));
                    });

                    $(newHTML).find("script").each(function() {
                        var src = $(this).attr("src");

                        if (!src) return true;

                        var $script = $("script[src='" + src + "']");
                        if (!$script.length) {
                            scripts += "<script src='" + src + "'></script>";
                        }
                    });

                    // append not loaded scripts
                    if (scripts) {
                        $("body").append(scripts);
                    }

                    // change titile
                    document.title = newHTML.title;

                    $(".current").fadeOut(100, function() {

                        // fade in images
                        fadeInImages();

                        $prevStyles.remove();

                        // body class
                        $("body")[0].className = newHTML.getElementsByTagName("body")[0].className;

                        var $new = $(newHTML).find(".current").hide();
                        $(this).replaceWith($new);

                        // fadeIn directly if not destined to previous
                        // if (window.fromPrev) {
                        //   $new.addClass("opaqueHidden");
                        // }

                        $new.fadeIn(100);

                        // change buttons
                        initVideoControls();

                        window.reset = true;

                        destroyFullpage();
                        initFullpage();
                        swipeBodyEvent();

                        // change next link
                        if ($(".section-footer").length) {
                            $(".work-next-project").remove();
                        } else {
                            $(".work-next-project").attr("href", $(newHTML).find(".work-next-project").attr("href"));
                        }

                        changeUrl(newHTML.getElementsByTagName("title")[0].innerHTML, href);
                        if (window.resetTimeout) clearTimeout(window.resetTimeout);
                        window.resetTimeout = setTimeout(function() {
                            window.reset = false;
                        }, 1000);
                    });

                }

                /////

                $(document).on("click", "a:not([href*='#']), a.sidebar-back-link", function(e) {
                    var $thisLink = $(this);
                    var href = $thisLink.attr("href");

                    if ($thisLink.is(".work-prev-project")) {
                        window.fromPrev = true;
                    }

                    if ($thisLink.data("category-hash")) {
                        window.hash = $thisLink.data("category-hash");
                        $("html").addClass("opaqueHidden");
                    }

                    if (href.indexOf(window.location.hostname) === -1) return;
                    e.preventDefault();

                    var newHTML = $thisLink.data("newHTML");

                    if (newHTML) {
                        console.log("from preload...");
                        replaceCurrent(newHTML, href);

                    } else {
                        console.log("just loaded...");

                        $.get(href, function(response) {
                            var parser = new DOMParser();
                            newHTML = parser.parseFromString(response, "text/html");

                            replaceCurrent(newHTML, href);

                        });
                    }

                });

                $(document).on("ready", function() {
                    initWantedScript();
                });


                ////// MENU


                (function() {
                    $(document).ready(function() {


                        var triggerBttn = document.getElementById('trigger-overlay'),
                            overlay = document.querySelector('div.overlay'),
                            closeBttn = overlay.querySelector('button.overlay-close');
                        transEndEventNames = {
                                'WebkitTransition': 'webkitTransitionEnd',
                                'MozTransition': 'transitionend',
                                'OTransition': 'oTransitionEnd',
                                'msTransition': 'MSTransitionEnd',
                                'transition': 'transitionend'
                            },
                            transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
                            support = { transitions: Modernizr.csstransitions };

                        function toggleOverlay(ovlay) {
                            $(document).trigger($.Event('resize'));

                            if ($(ovlay).hasClass('open')) {
                                $(ovlay).removeClass('open');
                                $(ovlay).addClass('close');
                                var onEndTransitionFn = function(ev) {
                                    if (support.transitions) {
                                        if (ev.propertyName !== 'visibility') return;
                                        this.removeEventListener(transEndEventName, onEndTransitionFn);
                                    }
                                    $(ovlay).removeClass('close');
                                };
                                if (support.transitions) {
                                    ovlay.addEventListener(transEndEventName, onEndTransitionFn);
                                } else {
                                    onEndTransitionFn();
                                }
                            } else if (!$(ovlay).hasClass('close')) {
                                $(ovlay).addClass('open');
                            }
                        }


                        $(document).on("click", "#trigger-overlay, button.overlay-close", function() {
                            toggleOverlay($("div.overlay")[0]);
                        });
                    });

                    // triggerBttn.addEventListener( 'click', toggleOverlay );
                    // closeBttn.addEventListener( 'click', toggleOverlay );
                })();



                ///// IMAGE LOAD

                // $.fn.bgLoaded = function(custom) {

                //   var self = this;

                //   // Default plugin settings
                //   var defaults = {
                //     afterLoaded : function(){
                //       this.addClass('bg-loaded');
                //     }
                //   };

                //   // Merge default and user settings
                //   var settings = $.extend({}, defaults, custom);

                //   // Loop through element
                //   self.each(function(){
                //     var $this = $(this),
                //         bgImgs = $this.css('background-image').split(', ');
                //     $this.data('loaded-count',0);

                //     $.each( bgImgs, function(key, value){
                //       var img = value.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
                //       $('<img/>').attr('src', img).on('load', function() {
                //         $(this).remove(); // prevent memory leaks
                //         $this.data('loaded-count',$this.data('loaded-count')+1);
                //         if ($this.data('loaded-count') >= bgImgs.length) {
                //           settings.afterLoaded.call($this);
                //         }
                //       });
                //     });

                //   });
                // };

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page

                // Example with different callback function
                // var startTime = new Date().getTime();
                // $('.section-home-intro').bgLoaded({
                //   afterLoaded : function(){
                //     this.addClass('loaded');
                //   }
                // });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'work': {
            init: function() {
                $.fn.fullpage.setAllowScrolling(true);
                if (window.location.hash) {

                    $(".current").addClass("opaqueHidden");
                    $.fn.fullpage.setScrollingSpeed(0);
                    $.fn.fullpage.moveTo(window.location.hash.replace(/#/g, ''), 1);
                    $.fn.fullpage.setScrollingSpeed(300);
                    $(".current.opaqueHidden").removeClass("opaqueHidden");
                }

                if (window.allow) clearTimeout(window.allow);
                window.allow = setTimeout(function() {
                    $.fn.fullpage.setAllowScrolling(true);
                }, 200);

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.